@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: "Desiderata";
    src: url("/public/assets/fonts/Desiderata-Regular.woff2");
  }
@font-face {
      font-family: "Adellesans";
      src: url("/public/assets/fonts/adellesans-regular-webfont.ttf");
}
@font-face {
      font-family: "Nunito";
      src: url("/public/assets/fonts/Nunito-VariableFont_wght.ttf");
}

